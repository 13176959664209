import perkAnimation1 from '../../images/illustrations/career-1/data.json'
import perkAnimation2 from '../../images/illustrations/career-2/data.json'
import perkAnimation3 from '../../images/illustrations/career-3/data.json'
import perkAnimation4 from '../../images/illustrations/career-4/data.json'


export const content = {
    title : 'Карьера в Студии',
    subtitle: 'Устали работать над непонятными и скучными проектами и хотите реализовать свой творческий потенциал? Не устраивает зарплата и условия работы? Чувствуете, что в итоге останетесь ни с чем? Приходите к нам!',
    text: 'Напишите нам в телеграм',
    button: {
        text: 'Написать в телеграм',
        url: 'https://jobs.lever.co/celestia'
    },
    perks:{
        title: 'Преимущества',
        perks: [{
                title: 'Есть возможность запустить с нами свой стартап.',
                image: 'careers/careers-1.svg',
                animation: perkAnimation1
            },{
                title: 'Есть менеджер проектов, который помогает и направляет, есть задачи, есть проекты.',
                image: 'careers/careers-2.svg',
                animation: perkAnimation2
            },{
                title: 'Зарплата зависит напрямую от навыков и объема работы.',
                image: 'careers/careers-3.svg',
                animation: perkAnimation3
            },{
                title: 'Гибкая и удаленная рабочая среда',
                image: 'careers/careers-4.svg',
                animation: perkAnimation4
            }
        ]
    }
}

export const FooterBoxes = [
    {
        title: 'Связаться с нами',
        text: 'Напишите нам и наши менеджеры обязательно с Вами свяжуться',
        button: {
            text: 'Контакты',
            href: '/contacts',
            id: 'operator',
            type: 'simple'
        }
    },
    {
        title: 'Присоединяйтесь в нашу группу Telegram',
        text: 'Все последние новости и события нашего агентство!',
        button: {
            text: 'Перейти',
            href: 'https://t.me/simbios_agency',
            id: 'career',
            type: 'simple'
        }
    }
]
